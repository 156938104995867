import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles/createTheme";
import dayjs from "dayjs";
import React from "react";
import Calendar from "react-calendar";

interface Props {
    theme: Theme | any;
    now: Date;
    holidayData: number[];
}
const Month: React.FC<Props> = ({ theme, now, holidayData }) => {
    if (holidayData == null || holidayData.length == 0) {
        return null;
    }

    return (
        <div>
            <Typography variant="h5" align="center" color="text.secondary" component="p" marginTop={'40px'}>
            <span>{now.getFullYear()}</span>年<span>{now.getMonth() + 1}</span>月の営業日
            </Typography>
            <Typography variant="h5" align="center" color="text.secondary" component="p" className='md-up-calender' sx={{
                [theme.breakpoints.down('md')]: {
                display: 'none'
                },
            }}>
            <Calendar
                value={now}
                showNavigation={false}
                showNeighboringMonth={false}
                calendarType={"Hebrew"}
                formatDay ={
                    (locale, date) => new Intl.DateTimeFormat(
                    'en-US', 
                    {
                        day: "2-digit"
                    }).format(date)  
                }
                tileClassName={({ activeStartDate, date, view }) => view === 'month' && (holidayData.includes(Number(dayjs(date).format('D')))) ? 'holiday' : null}  
                ></Calendar>      
            </Typography>
            <Typography variant="h5" align="center" color="text.secondary" component="p" sx={{
                [theme.breakpoints.up('md')]: {
                display: 'none'
                },
            }}>
            <Calendar
                value={now}
                showNavigation={false}
                showNeighboringMonth={false}
                calendarType={"Hebrew"}
                formatDay ={
                    (locale, date) => new Intl.DateTimeFormat(
                    'en-US', 
                    {
                        day: "2-digit"
                    }).format(date)  
                }
                tileClassName={({ activeStartDate, date, view }) => view === 'month' && (holidayData.includes(Number(dayjs(date).format('D')))) ? 'holiday' : null}  
                ></Calendar>      
            </Typography>
        </div>
    );
};

export default Month;