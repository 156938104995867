import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles/createTheme";
import React from "react";
import parse from 'html-react-parser';

interface Props {
    infoData: {title: string, content: string}[];
}
const Info: React.FC<Props> = ({ infoData }) => {
    if (infoData == null || infoData.length == 0) {
        return null;
    }

    return (
        <Container disableGutters maxWidth="lg" component="main" sx={{ pt: 8, pb: 5 }} id="info">
        <Typography
          component="h1"
          variant="h3"
          align="center"
          color="text.primary"
          gutterBottom
        >
          お知らせ
        </Typography>
        {infoData.map((info) => (
            <Card sx={{ mb: 3, mx: 3}}>
              <CardContent>
                <Typography variant="h5" align="center" color="text.secondary" component="p" sx={{ mb: 1}}>
                  {info.title}
                </Typography>
                <Typography variant="body2" align="left">
                  {parse(info.content)}
                </Typography>
              </CardContent>
            </Card>
          ))}
      </Container>
    );
};

export default Info;