import * as React from "react";
import { CSSProperties } from "react";
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import AppBar from '@mui/material/AppBar';

const defaultStyle: CSSProperties = {
  height: "101vh",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#2d1176",
  color: "#fff"
};

type Props = {
  children: React.ReactNode;
  inView: boolean;
  theme: any;
  style?: CSSProperties;
};

/**
 * ScrollWrapper directs the user to scroll the page to reveal it's children.
 * Use this on Modules that have scroll and/or observer triggers.
 */
const ScrollWrapper = ({ children, style, inView, theme, ...props }: Props) => {
  return (
    <div {...props}>
        <AppBar
            position="static"
            color="default"
            elevation={0}
        >
            {/* PC用・スマホ用ヘッダー */}
            {children}
            {/* PC用追従ヘッダー */}
            <Toolbar sx={{ 
                flexWrap: 'wrap',
                display: inView? 'none': 'inline', 
                position: 'fixed',
                width: '100%',
                class: 'fixd-header',
                backgroundColor: '#fffaf3',
                zIndex: 300,
                [theme.breakpoints.down('md')]: {
                display: 'none'
                },}}>
                <nav style={{float: 'right', paddingTop: '10px', paddingRight: '30px'}}>
                <Link
                    variant="button"
                    color="text.primary"
                    href="#reservation"
                    sx={{ my: 1, mx: 1.5, fontSize: '2em' }}
                >
                    ご予約
                </Link>
                <Link
                    variant="button"
                    color="text.primary"
                    href="#menu"
                    sx={{ my: 1, mx: 1.5, fontSize: '2em' }}
                >
                    メニュー
                </Link>
                <Link
                    variant="button"
                    color="text.primary"
                    href="#calender"
                    sx={{ my: 1, mx: 1.5, fontSize: '2em' }}
                >
                    営業時間
                </Link>
                <Link
                    variant="button"
                    color="text.primary"
                    href="#map"
                    sx={{ my: 1, mx: 1.5, fontSize: '2em' }}
                >
                    地図
                </Link>
                </nav>
            </Toolbar>
            {/* スマホ用追従ヘッダー */}
            <Toolbar sx={{ 
                flexWrap: 'wrap',
                display: inView? 'none': 'inline', 
                position: 'fixed',
                width: '100%',
                class: 'fixd-header',
                backgroundColor: '#fffaf3',
                zIndex: 300,
                [theme.breakpoints.up('md')]: {
                    display: 'none'
                },}}>
            <nav style={{paddingTop: '10px'}}>
                <Link
                variant="button"
                color="text.primary"
                href="#reservation"
                sx={{ my: 1, mx: 1.5, fontSize: '2em' }}
                >
                ご予約
                </Link>
                <Link
                variant="button"
                color="text.primary"
                href="#menu"
                sx={{ my: 1, mx: 1.5, fontSize: '2em' }}
                >
                メニュー
                </Link>
            </nav>
            <nav style={{paddingTop: '5px'}}>
                <Link
                variant="button"
                color="text.primary"
                href="#calender"
                sx={{ my: 1, ml: -2, mr:0, fontSize: '2em' }}
                >
                営業時間
                </Link>
                <Link
                variant="button"
                color="text.primary"
                href="#map"
                sx={{ my: 1, ml: 5, mr:1, fontSize: '2em' }}
                >
                地図
                </Link>
            </nav>
            </Toolbar>
        </AppBar>
    </div>
  );
};

export default ScrollWrapper;
